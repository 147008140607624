import '../services/device-type';
import '../services/lazy-load';
import '../vendor/owl-carousel';
SNI.Application.addModule('vote-carousel', (context) => {

  let deviceType = context.getService('device-type'),
      $element = $(context.getElement()),
      debug = context.getService('logger').create('module.vote-carousel'),
      startModule = context.getService('start-modules').start,
      template = context.getService('template'),
      galleryManager = context.getService('gallery-manager'),
      voteService = context.getService('vote-service'),
      check = context.getService('check').new(debug),
      config,
      $carouselContainer,
      $galleryContainer = $('.photocards'),
      $voteBtnTitleContainer = $('[data-vote-btn-title]'),
      galleryTitleContainer = '[data-vote-btn-title] .gallery-title',
      $voteBtn = $('[data-vote-btn-title] .vote-btn'),
      $voteCarouselBtnPlaceholder = $('<div class="is-Hidden"></div>'),
      $voteCarouselBtnContainer = $('[data-module=vote-carousel]'),
      carouselPos,
      $socialShare = $('.m-SocialIcons li'),
      $voteOptionsContainer = $('.vote-options'),
      $voteResultsContainer = $('[data-vote-results]'),
      $pageContainer = $('.container-site'),
      $slideshowWrapper,
      $slideshowContainer,
      results = null,
      // viewedGalleries = [], // Uncomment this to enable vote gating
      fromResultsPage = false; // 'true' if user navigates to a gallery by clicking a carousel item - (img, text link) on the results page
  //  let behaviors =   ['lazy-load'];
  const SniAds = context.getGlobal('SniAds'),
        utility = context.getService('utility'),
        /*Basic configuration for the carousel */
        defaults = {
          carouselSelector: '.vote-results',
          slideSelector: '[data-vote-gallery-thumb]',
          voteResultsHeaderSel : '[data-voting-refreshTitle]',
          voteResultsMsgContainer :'[data-voting-refreshTitle] .o-AssetTitle__a-HeadlineText',
          voteHeaderSel : '[data-voting-header]',
          activeClass: 'active',
          carouselType: 'results',
          center: true,
          items: 3,
          loop: true,
          dots: false,
          autoWidth: true,
          stagePadding: 15,
          componentTitle: 'results-carousel',
          cardSelector: '[data-card]',
          topClass: '[data-type="scroll-top"]',
          freshVote : true,
          firstTitleRun: true,
          useUpdatedResults: false,
          votedGalleryId: null,
          votingEnabled: false,
          isAdvancingCarousel: false,
          checkVisible: false,
          navText : ['<i aria-hidden="true"></i>','<i aria-hidden="true"></i>']
        };

  function updateObject(existing, updated) {
    return Object.assign({}, existing, updated);
  }

  function setConfig(oldSettings, newSettings) {
    config = updateObject(oldSettings, newSettings);
  }

  // Retrieve all the JSON data and store in the gallery manager, then remove from the DOM
  function buildGalleriesData($container) {
    let galleries = [];
    $container.find('[data-vote-index]').each(function(idx, el){
      let data = getJSONdata($(this));
      galleries.push(data);
      $(this).find('script').remove();
    });
    galleryManager.setGalleriesData(galleries);
  }

  function getJSONdata($el){
    /**
     * retrieve JSON data from each slide
     * In the future, it should retrieve the data based on the slide's index which should be retrieved using $.data('voteIndex')
     */
    return utility.getElementJSON($el);
  }

  function enableTracking() {
    if (window.clicktrackselect) {
      window.clicktrackselect();
    }
  }

  /*******************
   * Vote Button Functions
  *******************/

  function enableVoteButton() {
    $voteBtn.removeClass('is-Disabled');
    config.votingEnabled = true;
    $('.vote-banner__text').html('Click VOTE to vote for this gallery, or choose another from the carousel');
  }

  function disableVoteButton() {
    $voteBtn.addClass('is-Disabled');
    config.votingEnabled = false;
  }

  /*******************
   * Vote Banner Functions
  *******************/

  function addVoteBanner() {
    const bannerMarkup = template.voteBannerMarkup();
    const $galleryContainer = $('.o-PhotoGalleryPromo__m-Stage');
    if ($galleryContainer.length > 0) {
      $galleryContainer.append(bannerMarkup);
      $('.vote-banner__close').on('click', hideVoteBanner);
    }
  }

  function showVoteBanner() {
    $('.vote-banner').addClass('vote-banner--show-banner');
  }

  function hideVoteBanner() {
    $('.vote-banner').removeClass('vote-banner--show-banner');
  }

  /*******************
   * Functions to control whether a gallery has been viewed
  *******************/

  // Uncomment this to enable vote gating
  // function markGalleryViewed(index) {
  //   const numberOfGalleries = galleryManager.getAllGalleries();
  //   const gallerySelector = `[data-vote-index="${index}"]`;
  //   const $galleryItem = $(gallerySelector);
  //   if (!$galleryItem.hasClass('vote-checked')) {
  //     $galleryItem.append(template.checkMarkSVG());
  //     $galleryItem.addClass('vote-checked');
  //   }

  //   if (viewedGalleries.indexOf(defaults.selectedGalleryId) === -1) {
  //     viewedGalleries.push(defaults.selectedGalleryId);
  //   }

  //   if (viewedGalleries.length === numberOfGalleries.length) {
  //     debug.log('Enough galleries viewed, enable voting');
  //     if (!$('.vote-banner').hasClass('.vote-banner--show-banner')) {
  //       showVoteBanner();
  //     }
  //     enableVoteButton();
  //   } else {
  //     debug.log('Not enough galleries viewed. viewedGalleries:', viewedGalleries.length, 'numberOfGalleries:', numberOfGalleries.length);
  //   }
  // }

  // Display VOTE btn, gallery title
  function displayVoteBtnGalleryTitle(displayTitle) {
    if (displayTitle) {
      $voteBtn.attr('title', displayTitle);
      $(galleryTitleContainer).html(displayTitle);
    }
  }

  function setGalleryData(index) {
    let galleryData = {};
    if (typeof index!== 'undefined') {
      galleryData = galleryManager.getGalleryData(index);
      defaults.selectedGalleryIndex = index;
      defaults.selectedGalleryTitle = galleryData.galleryTitle;
      defaults.selectedGalleryId = galleryData.assetId;
      defaults.assetType = galleryData.assetType;
    }
    return galleryData;
  }

  function processSlide(index, init) {
    let galleryData = setGalleryData(index);
    // Uncomment this to enable vote gating
    // if (!deviceType.isMobile && config.freshVote) {
    //   markGalleryViewed(index);
    // }
    if (galleryData.displayTitle!== '') {

      if (!init) {
        displayVoteBtnGalleryTitle(galleryData.displayTitle);
      }
      // If user has already voted, show 'YOUR VOTE' (or) 'VOTE' on the disabled 'vote' button
      // If voting has ended on a campaign, show 'WINNER' on the disabled 'vote' button
      if (!config.freshVote) {
        if (config.voteCampaignID && config.winningGalleryId && !config.isVotingAllowed) {
          let $voteBtn = $('.vote-btn');
          if (config.winningGalleryId === defaults.selectedGalleryId) {
            if (!$voteBtn.length) {
              $voteBtnTitleContainer.prepend('<div class="vote-btn-container"><div class="vote-btn is-Disabled">WINNER</div></div>');
            } else {
              $voteBtn.removeClass('is-Hidden');
            }
            $(galleryTitleContainer).removeClass('center');
          } else {
            if ($voteBtn.length) {
              $(galleryTitleContainer).addClass('center');
              $voteBtn.addClass('is-Hidden');
            }
          }
        } else if (config.votedGalleryId === defaults.selectedGalleryId) {
          showVoted();
        } else {
          $voteBtn.html('VOTE').removeClass('is-Voted').hide();
          centerTitle(true);
        }
      }
    }
  }

  function switchGallery(data) {
    processSlide(data.index);
    if (deviceType.isMobile) {
      let skipRender = galleryManager.hasBeenPrefetched(data.index,'vc');
      if (skipRender) {
        debug.log('Process slide skip render');
        context.broadcast('mobile.carousel.slide.advance', data);
      } else {
        debug.log('Process slide clicked');
        context.broadcast('mobile.carousel.slide.clicked', data);
      }
    } else {
      context.broadcast('vote.carousel.slide.clicked', data);
    }
  }

  // Render mobile - vertical gallery
  function renderVerticalGallery(index, init) {
    let data = {
      index,
      init
    };
    $galleryContainer.attr('data-module','vote-vertical-gallery');
    if (init) { // init only once, unless there is a reason to do it multiple times
      startModule($galleryContainer.get(0));
    } else {
      data.scrollTo = true;
    }
    switchGallery(data);
  }

  function setMobileResultsTitleFromIndex(galleryData, index) {
    let $desc = $('[data-vote-item-description]');
    $desc.removeClass('is-Hidden');
    $desc.find('a').attr('title', galleryData.displayTitle).text(galleryData.displayTitle);
  }


  // * Remove after launch  - use
  function setMobileResultsTitle($slide, isAppend) {
    let index= $slide.data('vote-index');
    let customTitle = $slide.data('custom-title');

    let data = {
      componentTitle: defaults.componentTitle,
      currentTitle: customTitle
    };
    let aWinner = template.trackableLink(data) || data.customTitle;
    if (aWinner) {
      let $desc = $('[data-vote-item-description]');
      let analyticsAttr = {
        cardPosition: index,
        cardType: 'normal'
      };
      if (isAppend) {
        $desc.html(aWinner);
      } else {
        $desc.find('a').attr('title', customTitle).text(customTitle); // don't overwrite the anchor so the tracking is maintained
      }
      $desc.attr('data-analytics', JSON.stringify(analyticsAttr));  // update the analytics data for mobile titles
    }
  }

  function getVotes() {
    if (config.voteCampaignID) {
      voteService.getVotes(config.voteAPI, config.voteCampaignID);
    }
  }

  function showResultsMessage() {
    let msg = config.voteResultsMessage;
    $(defaults.voteHeaderSel).addClass('is-Hidden');
    $(defaults.voteResultsHeaderSel).removeClass('is-Hidden');
    if (msg) {
      $(defaults.voteResultsMsgContainer).html(config.voteResultsMessage);
    }
  }

  function setInitialMobileTitle(index) {
    let galleryData = galleryManager.getGalleryData(index);
    setMobileResultsTitleFromIndex(galleryData, index);
  }

  function showNewsletter() {
    $('.vote-reminder').removeClass('is-Hidden');
  }

  function displayVoteResults(selectedID) {
    let resultPageHeaderPosition = $pageContainer.offset() ? $pageContainer.offset().top : 0,
        offset = 0;

    $('.check-mark-svg').remove();
    $('.vote-banner').remove();
    resultsCarousel(selectedID);
    showResultsMessage();
    if (deviceType.isMobile) {
      let socialShareHeight = $socialShare.length ? $socialShare.height() : 0;
      offset = resultPageHeaderPosition - socialShareHeight;
      setInitialMobileTitle(defaults.selectedGalleryIndex);
    } else {
      offset = resultPageHeaderPosition;
      if (SniAds.getDefinedSlots() && SniAds.getDefinedSlots().dfp_leaderboard) {
        let $leaderboard = SniAds.getDefinedSlots().dfp_leaderboard.getSlotElementId() ? $('#'+ SniAds.getDefinedSlots().dfp_leaderboard.getSlotElementId()) : null;
        if ($leaderboard) {
          offset -= ($leaderboard.height() - 20);
        }
      }
    }
    window.scrollTo(0, offset);
    showNewsletter();
  }

  function resultsCarousel(selectedID) {
    let $RS, mobileContainer;    // Royal slider
    $voteOptionsContainer.addClass('is-Hidden');
    let galleries = galleryManager.getAllGalleries();
    let $originals = $voteOptionsContainer.find('.owl-item').filter(':not(.cloned)')
      .find('[data-carousel-slide]').clone();

    $carouselContainer.trigger('owl.carousel.destroy');

    // vote-results carousel config
    let owlconfig = {
      carouselSelector: '.vote-results',
      slideSelector: '.m-MediaBlock',
      activeClass: 'active',
      carouselType: 'results',
      center: true,
      items: 3,
      loop: true,
      dots: false,
      navText : ['<i aria-hidden="true"></i>','<i aria-hidden="true"></i>'],
      autoWidth: true,
      stagePadding: 15,
      componentTitle: 'results-carousel',
      slideBy: 3
    };

    /*
      clear out slide show container and mobile cards container
      Clear out Royal Slider
      Hide unneeded elements
      Then set up a new results carousel
    */

    $voteOptionsContainer.remove();
    $voteBtnTitleContainer.hide(); // Remove vote button, gallery title container - blue gradient bar

    if (!deviceType.isMobile) {
      $originals.each(function(index) {
        let data = galleries[index];
        let $element = $(this);
        let id = $element.data('asset-id');
        let itemisWinner = id === selectedID;
        let galleryAttribute = {
          title: data.displayTitle,
          isWinner: itemisWinner? template.voteWinnerTitle() :'',
          //href: data.galleryURL,
          componentTitle: defaults.componentTitle
        };
        $element.append(template.voteResultGalleryItemTextWrap(galleryAttribute));
      });
    }

    if (deviceType.isMobile) {
      // set your vote
      // set single gallery title
      mobileContainer = $('[data-module="vote-vertical-gallery"]').get(0);
      SNI.Application.stop(mobileContainer);
      $('.o-Vote.is-Inline').addClass('is-Hidden');
      $('.vote-responsive .container-site.is-Fluid').addClass('is-Results');
    } else {
      $slideshowWrapper = $('[data-module="base-inline-gallery"]');
      $slideshowWrapper.find('.m-StageWrapper').addClass('is-Results');
      $slideshowContainer = $slideshowWrapper.find('.slideshow-wrapper');
      $slideshowContainer.addClass('is-Hidden'); // Remove slideshow
      $slideshowWrapper.find('.m-AssetDataWrap').addClass('is-Hidden');
      $RS = $slideshowContainer.data('royalSlider');
      $RS.destroy();
      $RS = $slideshowContainer.empty();
    }

    $voteResultsContainer = $('[data-vote-results]');

    // Update analytics component name
    $originals.each(function(){
      let $item = $(this);
      let analytics = { componentTitle: defaults.componentTitle };
      $item.find('[data-type="gallery-select"]').removeAttr('data-type').attr('data-analytics-comp', JSON.stringify(analytics));
    });
    $voteResultsContainer.append($originals);
    // Link to the selected gallery from results page
    $voteResultsContainer.on('click', '.owl-item', function(e) {
      if (!deviceType.isMobile && !deviceType.isTablet) {
        let $slide = $(this).find('[data-carousel-slide]'),
            id = $slide.data('asset-id');
        sessionStorage.setItem('resultsSelectedGalId', id); // this will be deleted upon redirect to selected gallery
      }
      window.location.href = window.location.pathname;
    });
    if (deviceType.isMobile) {
      $('[data-vote-item-description]').on('click', function(e) {
        window.location.href = window.location.pathname;
      });
    }
    let $winner = $voteResultsContainer.find(`[data-asset-id="${selectedID}"]`);
    $winner.addClass('winner');
    if (deviceType.isMobile) {
      $voteResultsContainer.find('[data-asset-id]').each(function() {
        $(this).find('.m-MediaBlock__m-MediaWrap a').append('<span class="gallery-vote-percent is-Hidden"></span>');
      });
      $winner.append(template.voteWinnerTitle());
    }
    let $winnerFix = $winner.detach();
    $voteResultsContainer.prepend($winnerFix);
    $voteResultsContainer.on('initialized.owl.carousel', () =>  {
      enableTracking();
    });
    $voteResultsContainer.on('translated.owl.carousel', handleResultsTranslate);
    $voteResultsContainer.owlCarousel(owlconfig);
  }

  function centerTitle(on) {
    if (deviceType.isMobile) {
      let $title = $voteBtnTitleContainer.find('.gallery-title');
      if (on) {
        $title.addClass('center');
      } else {
        $title.removeClass('center');
      }
    }
  }

  // 'YOUR VOTE' label
  function showVoted() {
    $voteBtn.html('YOUR VOTE').addClass('is-Voted').show();
    centerTitle(false);
  }

  function resultsPageView() {
    galleryManager.resultsPageView();
  }

  const submitVote = () => {
    let dupVote = voteService.isDuplicateVote();

    if (dupVote) {
      debug.log('User already voted, do nothing');
    } else if (config.votingEnabled) {
      debug.log('Voting enabled, cast vote');
      voteService.setLStorItem('loadedState', 'vote.voted');
      config.freshVote = false;
      config.votedGalleryId = defaults.selectedGalleryId;
      let data = {
        campaignId: config.voteCampaignID,
        assetId: defaults.selectedGalleryId,
        useInlineResults: config.useInlineResults,
        voteAPI: config.voteAPI,
        type: defaults.assetType
      };
      disableVoteButton();
      // Submit vote
      voteService.recordVote(data);
      defaults.useUpdatedResults = true;
      if (!config.displayResults) {
        displayVoteResults(defaults.selectedGalleryId);
        resultsPageView();
      }
    } else {
      debug.log('Voting not enabled yet, show banner');
      showVoteBanner();
    }
  };

  function bindVoteBtnClick() {
    // test error handling
    if ($voteBtn.length) {
      $voteBtn.off().on('click', submitVote);
    }
  }

  // Sticky carousel
  function makeCarouselSticky() {
    if ($voteCarouselBtnContainer.length) {
      let showStickyCarousel = false;
      let carPos = $voteCarouselBtnContainer.get(0).getBoundingClientRect();
      let ht = carPos.height + 20;
      let pcTop;
      let $pcInner = $('.photocards-inner');
      let pcInnerBottom = $pcInner.get(0).getBoundingClientRect().bottom;
      let isLast = galleryManager.isOnLastGallery();
      let nearBottom = isLast && (pcInnerBottom < (window.innerHeight /2));
      if (typeof carouselPos === 'undefined') {
        pcTop = $pcInner.offset().top;  // only set it once
      }

      if (($(window).scrollTop() > (pcTop - ht)) && !nearBottom) {
        showStickyCarousel = true;
      }

      if (showStickyCarousel) {
        if (!($voteCarouselBtnContainer.find('.carouselPlaceholder').length)) {
          $voteCarouselBtnPlaceholder.insertBefore($voteCarouselBtnContainer);
          $voteCarouselBtnPlaceholder.css({height: $voteCarouselBtnContainer.height()});
          $voteCarouselBtnPlaceholder.addClass('carouselPlaceholder').removeClass('is-Hidden');
        }
        $voteCarouselBtnContainer.addClass('sticky');
      } else {
        $voteCarouselBtnPlaceholder.addClass('is-Hidden');
        $voteCarouselBtnPlaceholder.remove();
        $voteCarouselBtnContainer.removeClass('sticky');
      }
    }
  }

  function callMobileResultsTitleFromIndex($mediaBlock){
    let index = $mediaBlock.data('block-index');
    let data = galleryManager.getGalleryData(index);
    setMobileResultsTitleFromIndex(data, index);
  }

  function checkStorage() {
    if (!config.isVotingAllowed) {
      config.freshVote = false;
      getVotes();
      return;
    }
    if (check.supports('localStorage')) {
      var ls = voteService.getLStorItem('votes'),
          votes = ls ? JSON.parse(ls) : null,
          dupVote = ls ? voteService.searchJSONArray(votes, 'voteURL', window.location.href.split('?')[0]) : false,
          now = new Date().getTime(),
          voteTime = voteService.getLStorItem('voteTime');

      if (voteTime === null) {
        config.freshVote = true;
        debug.log('checkStorage: freshVote');
      } else if (voteTime-now > 0 && votes !== null) { // If user already voted today
        // Gallery already voted - duplicate
        if (dupVote) {
          config.freshVote = false;
          config.votedGalleryId = voteService.getVotedGallery('id');

          debug.log('checkStorage: oldVote');
        }
      } else if (voteTime-now <= 0) { // Clear old votes prior to today from user's localstorage
        debug.log('checkStorage: resetVote');
        voteService.removeLStorItem('voteTime');
        voteService.removeLStorItem('voteState');
        voteService.removeLStorItem('votes');
      }
    } else {
      debug.log('checkStorage: no localstorage, exiting');
    }
  }

  const handleResultsTranslate = (event) => {
    if (deviceType.isMobile) {
      let currentIndex = event.item.index;
      var currentSlide = event.relatedTarget.$stage.children()[currentIndex];
      let mediaBlock = $(currentSlide).find('.m-MediaBlock');
      if (defaults.useUpdatedResults) {
        callMobileResultsTitleFromIndex(mediaBlock);
      } else {
        setMobileResultsTitle(mediaBlock);
      }
    }
  };

  // Show/hide carousel nav - prev, next icons
  function showCarouselNav(e) {
    let $prevCarousel = $('.owl-prev'),
        $nextCarousel = $('.owl-next'),
        $owlControls = $('.owl-controls'),
        windowWidth = $('.container-site').width() || window.innerWidth,
        carouselItemWidth = $('.owl-item').width(),
        totalCarouselItems = e.item.count,
        visibleCarouselItems = Math.round(windowWidth/carouselItemWidth);

    $prevCarousel.addClass('is-Hidden');

    // hide nav icons (both prev and next) if total items is less than window width
    if (e.item.index === 0 && totalCarouselItems <= visibleCarouselItems) {
      $owlControls.addClass('is-Hidden');
    } else {
      // 'Prev' icon
      if (e.item.index > 0) {
        $owlControls.removeClass('is-Hidden');
        $prevCarousel.removeClass('is-Hidden');
      }
      // 'Next' icon
      if (e.item.index <= (totalCarouselItems - visibleCarouselItems)) {
        $owlControls.removeClass('is-Hidden');
        $nextCarousel.removeClass('is-Hidden');
      } else {
        $nextCarousel.addClass('is-Hidden');
      }
    }
  }

  function handleOptionsTranslate(event) {
    let currentIndex, currentSlide, $slide, voteIndex;
    if (deviceType.isMobile) {
      currentIndex = event.item.index;
      currentSlide = event.relatedTarget.$stage.children()[currentIndex];
      $slide = $(currentSlide);
      voteIndex = $slide.find('[data-vote-index]').data('vote-index');
      galleryManager.setCurrentGallery(voteIndex);
      galleryManager.setActiveSlide(0, true);
      if (config.isAdvancingCarousel) {
        processSlide(voteIndex);
      } else {
        renderVerticalGallery(voteIndex, false);
      }
    }
    if (!deviceType.isMobile && config.isAdvancingCarousel) {
      currentIndex = 0;

      // Increment carousel selected index based on currently selected item index
      if ($carouselContainer.find('.is-Selected').data('vote-index') >= 0) {
        currentIndex = $carouselContainer.find('.is-Selected').data('vote-index') + 1;
      } else if ($carouselContainer.find('.is-Selected [data-vote-index]').data('vote-index') >= 0) {
        currentIndex = $carouselContainer.find('.is-Selected [data-vote-index]').data('vote-index') + 1;
      }

      currentSlide = event.relatedTarget.$stage.children()[currentIndex];
      $slide = $(currentSlide);
      voteIndex = $slide.find('[data-vote-index]').data('vote-index');
      if (!deviceType.isMobile) {
        switchGallery({index: voteIndex, init:false});
      }

      $carouselContainer.find('.is-Selected').removeClass('is-Selected');
      $slide.find('.m-MediaBlock').addClass('is-Selected');
    }
    config.isAdvancingCarousel = false;
    galleryManager.suspendChecks(false);
    config.advanceOnClick = false;
  }

  return {
    onclick: function(event, element, elementType) {
      switch (elementType) {
        case 'gallery-select':
          /*
            Handles user clicking on any of the top "bubbles"
            Desktop: Update data to new slider
            Mobile: Advance Carousel if it is a different item
          */
          event.preventDefault() && event.stopPropagation();
          let $slide = $(element).parentsUntil('[data-carousel-slide]');
          let voteIndex = $slide.data('vote-index');
          if ($slide) {
            if ($slide.hasClass('is-Selected')||(deviceType.isMobile && $slide.hasClass('center'))) return; // no need to switch slider
            if (deviceType.isMobile) {
              galleryManager.suspendChecks(true);
              config.isAdvancingCarousel = false;
              $carouselContainer.trigger('to.owl.carousel',[voteIndex,100,true]);
              return;
            } else {
              // On desktop, we should advance to the clicked item
              // This functionality assumes that looping is set as FALSE
              let $sp = $slide.parents('.owl-item');
              let $active = $carouselContainer.find('.owl-item');
              let pindex = $active.index($sp);
              config.isAdvancingCarousel = false;
              $carouselContainer.trigger('to.owl.carousel',[pindex, 200, true]);
              switchGallery({index: voteIndex, init:false});
            }
            // If it is a new item selected, continue
            $carouselContainer.find('.is-Selected').removeClass('is-Selected');
            $slide.addClass('is-Selected');
            return false;
          }
          break;
        default:
          debug.log('default');
          break;
      }
    },
    init() {
      if (!deviceType.isMobile && !deviceType.isTablet) {
        fromResultsPage = sessionStorage.getItem('resultsSelectedGalId') && sessionStorage.getItem('resultsSelectedGalId').length ? true : false;
      }
      setConfig(defaults, context.getConfig());
      $carouselContainer = $element;
      checkStorage();
      if (config.carouselType && config.carouselType === 'lead') {
        if (!deviceType.isMobile) {
          config.loop = false;
        }
        bindVoteBtnClick();
        // Uncomment this and delete the if block after to enable vote gating
        // if (deviceType.isMobile && config.freshVote) {
        //   enableVoteButton();
        // }
        if (config.freshVote) {
          enableVoteButton();
        }

        $carouselContainer = $element.find(config.carouselSelector);
        buildGalleriesData($carouselContainer);
        $carouselContainer.on('translate.owl.carousel drag.owl.carousel', (event) => {
          galleryManager.suspendChecks(true);
        });
        $carouselContainer.on('translated.owl.carousel', handleOptionsTranslate);
        if (!(deviceType.isMobile && config.isAdvancingCarousel) && config.isVotingAllowed) {
          processSlide(0, true);  // Set gallery data, and update button if needed
        }
        $carouselContainer.on('initialized.owl.carousel', () =>  {
          if (!deviceType.isMobile) {
            $('.owl-prev').addClass('is-Hidden');
          }
          if (deviceType.isMobile) {
            renderVerticalGallery(0, true);
          }
          // Enable click tracking on links
          enableTracking();

          // redirected from 'results' page (freshvote = false --> as user has already voted on this campaign)
          if (!config.freshVote && fromResultsPage && !deviceType.isMobile && !deviceType.isTablet) {
            let galleryId = sessionStorage.getItem('resultsSelectedGalId'),
                voteIndex = $carouselContainer.find(`[data-asset-id=${galleryId}] [data-vote-index]`).data('vote-index');

            context.broadcast('results.carousel.clicked', {index: voteIndex, init: true});
            // owl-item with voteIndex other than '0' must be translated to view as the first visible item on the carousel
            // carousel is zero-indexed
            if (voteIndex) {
              // display selected gallery carousel item as the first visible item on the carousel
              $carouselContainer.trigger('to.owl.carousel',[voteIndex, 200, true]);
              $carouselContainer.find('.is-Selected').removeClass('is-Selected');
              $carouselContainer.find(`[data-asset-id=${galleryId}]`).addClass('is-Selected');
              processSlide(voteIndex, false);
            }
          }
        });
        if (!deviceType.isMobile && config.freshVote) {
          addVoteBanner();
        }
      }

      //Scroll to top
      if (deviceType.isMobile) {
        $(config.topClass).on('click.top', function() {
          $('html,body').animate({
            scrollTop: 0
          }, 1000);
        });
      }

      // Sticky carousel (mobile only)
      if (deviceType.isMobile) {
        let stickyCarouselHandler = utility.throttle(makeCarouselSticky, 30);
        $(document).on('scroll.vote-responsive', stickyCarouselHandler);
      } else {
        // Show/hide carousel nav
        $carouselContainer.on('initialized.owl.carousel changed.owl.carousel resized.owl.carousel', showCarouselNav);
      }

      debug.log('Config', config);
      $carouselContainer.owlCarousel(config);
    },
    onmessage: {
      // Display vote results
      'vote-results-ready': (data) => {
        if (config.isVotingAllowed) {
          displayVoteResults(defaults.selectedGalleryId);
          if (data && $voteResultsContainer.length) {
            results = data;
            results.vote_items.forEach(function(gallery) { gallery.percent = (gallery.votes*100/results.total_votes); });

            $voteResultsContainer.find('[data-asset-id]').each(function() {
              let index = (results.vote_items.findIndex(gallery => gallery.id === $(this).attr('data-asset-id'))),
                  percent = 0;
              if (index >= 0) {
                percent = Math.floor(results.vote_items[index].percent);
              }
              $(this).attr('vote-percent', percent);
              $(this).find('.gallery-vote-percent').removeClass('is-Hidden');
              $(this).find('.gallery-vote-percent').html(percent+'%');
            });
          }
        } else {
          if (data && $voteResultsContainer.length) {
            results = data;
            let max = Math.max.apply(null, results.vote_items.map((gallery) => gallery.votes));
            let winner = results.vote_items.find(gallery => gallery.votes === max);
            config.winningGalleryId = winner.id;
            processSlide(0, true);
          }
        }
        debug.log('Save vote results');
      },
      'vote-submitted': (data) => {
        if (config.displayResults) {
          getVotes();
        }
      },
      'vote-submit-failed': (err) => {
        displayVoteResults(defaults.selectedGalleryId);
      },
      'vote-results-failed': (err) => {
        displayVoteResults(defaults.selectedGalleryId);
      },
      'switch-to-gallery': (data) => {
        let to = data.to;
        config.isAdvancingCarousel = true;
        if (typeof to !== 'undefined') {
          $carouselContainer.trigger('to.owl.carousel',[to,100, true]);
        }
      },
      'stream-next-gallery': () => {
        config.isAdvancingCarousel = true;
        $carouselContainer.trigger('next.owl.carousel');
      },
      'stream-prev-gallery': (data) => {
        config.goingBack = true;
        config.isAdvancingCarousel = true;
        $carouselContainer.trigger('prev.owl.carousel');
      }
    },
  };
});
